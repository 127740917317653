import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";

import GppGoodSharpIcon from "@mui/icons-material/GppGoodSharp";
import LocalActivitySharpIcon from "@mui/icons-material/LocalActivitySharp";
import PsychologySharpIcon from "@mui/icons-material/PsychologySharp";
import ReceiptSharpIcon from "@mui/icons-material/ReceiptSharp";
import { Modal } from "@mantine/core";
import { useState } from "react";

export const Skills = () => {
  const [opened, setOpened] = useState(false);
  const [activeSkill, setActiveSkill] = useState({ heading: "", details: "" });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      {/* Modal Popup for skill details */}

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={activeSkill.heading}
        zIndex={100}
        centered
      >
        {/* Modal content */}
        {activeSkill.details}
      </Modal>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Core values</h2>
              <p>
                Our <b>mission</b> is; To deliver safe, sustainable,
                high-quality projects and services which uphold our values and
                the ‘Ecicuncu’ legacy.
                <br></br> Our <b>core values</b> include; Quality, Reliability,
                Innovation and Accountability
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div
                  className="item"
                  onClick={() => {
                    setActiveSkill({
                      heading: "Quality",
                      details:
                        "We are relentlessly focused on executing to the highest standards for our clients and communities. We give our very best on every task we perform as our personnel are highly skilled, experienced and motivated to accomplish their assignments. We perform periodic tests on all our products and during service delivery to conform to the International specifications.",
                    });
                    setOpened(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <GppGoodSharpIcon
                    color="secondary"
                    fontSize="large"
                    style={{ marginBottom: "1rem" }}
                  />
                  <h5>Quality</h5>
                </div>
                <div
                  className="item"
                  onClick={() => {
                    setActiveSkill({
                      heading: "Reliability",
                      details:
                        "We are relentlessly focused on executing to the highest standards for our clients and communities. We give our very best on every task we perform as our personnel are highly skilled, experienced and motivated to accomplish their assignments. We perform periodic tests on all our products and during service delivery to conform to the International specifications.",
                    });
                    setOpened(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <LocalActivitySharpIcon
                    color="success"
                    fontSize="large"
                    style={{ marginBottom: "1rem" }}
                  />
                  <h5>Reliability</h5>
                </div>
                <div
                  className="item"
                  onClick={() => {
                    setActiveSkill({
                      heading: "Innovation",
                      details:
                        "At Ecicuncu, we anticipate change and shape it to fit our purposes. We acknowledge the weaknesses within our industry and create ethical, forward thinking solutions to overcome them. We identify, develop and deploy leading edge construction methods, plant and equipment and process improvement tools. All this is aimed at creating solutions to not only local but also global problems in the construction sector.",
                    });
                    setOpened(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <PsychologySharpIcon
                    color="primary"
                    fontSize="large"
                    style={{ marginBottom: "1rem" }}
                  />
                  <h5>Innovation</h5>
                </div>
                <div
                  className="item"
                  onClick={() => {
                    setActiveSkill({
                      heading: "Accountability",
                      details:
                        "We are accountable to our Customers, Company, Teammates, Subcontractors and Suppliers and ultimately to God. We create a conducive platform for all our employees at all levels to ably satisfy the needs of our clients based on the available time, costs, specified quality and scope. Ecicuncu values efficient and effective utilization of the available resources while executing any assignme",
                    });
                    setOpened(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <ReceiptSharpIcon
                    //color="primary"
                    fontSize="large"
                    style={{ marginBottom: "1rem" }}
                  />
                  <h5>Accountability</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
