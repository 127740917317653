import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";

import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

//images
import projImg1 from "../assets/img/1 (1).jpg";
import projImg2 from "../assets/img/1 (2).jpg";
import projImg3 from "../assets/img/1 (3).jpg";
import projImg4 from "../assets/img/1 (4).jpg";
import projImg5 from "../assets/img/1 (5).jpg";
import projImg6 from "../assets/img/1 (6).jpg";
import projImg7 from "../assets/img/1 (7).jpg";
import projImg8 from "../assets/img/1 (8).jpg";
import projImg9 from "../assets/img/1 (9).jpg";
import projImg10 from "../assets/img/1 (10).jpg";
import projImg11 from "../assets/img/1 (11).jpg";
import projImg12 from "../assets/img/1 (12).jpg";
import projImg13 from "../assets/img/1 (13).jpg";
import projImg14 from "../assets/img/1 (14).jpg";
import projImg15 from "../assets/img/1 (15).jpg";
import projImg16 from "../assets/img/1 (16).jpg";
import projImg17 from "../assets/img/1 (17).jpg";
import projImg18 from "../assets/img/1 (18).jpg";
import projImg19 from "../assets/img/1 (19).jpg";
import projImg20 from "../assets/img/1 (20).jpg";
import projImg21 from "../assets/img/1 (21).jpg";
import projImg22 from "../assets/img/1 (22).jpg";
import projImg23 from "../assets/img/1 (23).jpg";
import projImg24 from "../assets/img/1 (24).jpg";

export const Projects = () => {
  const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg4,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg5,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg6,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg7,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg8,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg9,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg10,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg11,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg12,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg13,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg14,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg15,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg16,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg17,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg18,
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects and Scope of works</h2>
                  <p>
                    Our services cover a wide range of civil engineering
                    projects like roads, water, buildings, transport,
                    geotechnical among others. We are committed to develop the
                    ideas through our design teams up to actual fulfillment of
                    your dreams. At Ecicuncu, our services include the
                    following;
                  </p>
                  <p className="mr-5" style={{ textAlign: "left" }}>
                    <ul>
                      <li>Road construction and maintenance</li>
                      <li>Design and Construction of buildings</li>
                      <li>Structural renovation works</li>
                      <li>Construction Management </li>
                      <li>Landscaping and gardening</li>
                      <li>Asset Management and Maintenance</li>
                      <li>Supply of Construction Materials and labour</li>
                    </ul>
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Tab 1</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Tab 3</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            if (index < 6) {
                              return <ProjectCard key={index} {...project} />;
                            } else {
                              return;
                            }
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projects.map((project, index) => {
                            if (index > 5 && index < 12) {
                              return <ProjectCard key={index} {...project} />;
                            } else {
                              return;
                            }
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {projects.map((project, index) => {
                            if (index > 11 && index < 18) {
                              return <ProjectCard key={index} {...project} />;
                            } else {
                              return;
                            }
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
